* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-Bold.eot');
  src: url('./assets/fonts/SFProText-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFProText-Bold.woff2') format('woff2'),
      url('./assets/fonts/SFProText-Bold.woff') format('woff'),
      url('./assets/fonts/SFProText-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-Semibold.eot');
  src: url('./assets/fonts/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFProText-Semibold.woff2') format('woff2'),
      url('./assets/fonts/SFProText-Semibold.woff') format('woff'),
      url('./assets/fonts/SFProText-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-Regular.eot');
  src: url('./assets/fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFProText-Regular.woff2') format('woff2'),
      url('./assets/fonts/SFProText-Regular.woff') format('woff'),
      url('./assets/fonts/SFProText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SFProText-Medium.eot');
  src: url('./assets/fonts/SFProText-Medium.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/SFProText-Medium.woff2') format('woff2'),
      url('./assets/fonts/SFProText-Medium.woff') format('woff'),
      url('./assets/fonts/SFProText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

:root {
  --white: #ffffff;
  --white-2: #fafbff;
  --black: #000000;
  --red: #FF2E00;
  --gray: #A4B2CE;
  --gray-1: #6B7083;
  --gray-2: #5f6368;
  --gray-3: #3c4043;
  --gray-4: #202124;
  --gray-10: #D8E0F0;
  --gray-20: #C8D2E6;
  --blue: #08cbff;
  --blue-2: #1890ff;
  --blue-3: #0088d1;
  --blue-4: #4385f5;
  --blue-5: #183b56;
  --blue-6: #0b1a30;
  --blue-7: #030A15;
  --green: #3AB54A;
  --orange: #ffc107;


  --neutral-20: #F3F4F5;

  --accent: #4385F5;
  --primary: var(--blue);
  --primary-dark: var(--blue-6);
  --secondary: var(--gray);
  --success: var(--green);
  --warning: var(--orange);
  --danger: var(--red);
  --layout-bg-color: #F9FAFD;
  --shadow: #e7eaf6;

  --light-bg: rgba(172, 172, 172, 0.8);
  --light-bg-2: #F6F7FB;
  --light-text: rgba(45, 44, 76, 0.85);

  /* NEW VARIABLES */
  --primary-new: #35B678;

  --gray-40: rgba(164, 178, 206, 0.4);

  --dark-40: rgba(11, 26, 48, 0.4);
  --dark: #0B1A30;
  --action-button-color: #D9D9D9;
  --chat-input-background-color: #F8F9FD;
  --video-background-color: #26292D;
  --video-background-color-light: #3d3e41;
}

.svg {
  color: var(--white)
}

body {
  background-color: var(--black);
  font-family: 'SF Pro Text', sans-serif;
}

video::-webkit-media-controls {
  display: none !important;
}

button {
  outline: none;
}
